import React, { useState } from 'react';
import axios from 'axios';
import './Rating.css'; 

const StressSurvey = () => {
  const [rating, setRating] = useState(11); 
  
  const handleRatingChange = async (event) => {
    const newRating = parseInt(event.target.value);
    setRating(newRating);
    try {
      const response = await axios.post('https://api.datascalp.com/apiv1/flightbooking/teamStressSurveysadddb', {
        rating: newRating,
      });
      console.log("response",response);
      if (response.status === 200) {
        console.log('Rating submitted successfully',response);
        window.location.href = `/thanksyou`;
      } else {
        console.error('Failed to submit rating');
      }
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const stressLevels = {
    10: 'Intolerable Stress',
    9: '',
    8: '',
    7: '',
    6: '',
    5: 'Good Balance of Stress with Comfort',
    4: '',
    3: '',
    2: '',
    1: '',
    0: 'Zero Stress/Bored',
  };

  return (
    <div className="survey-container">
      <h1>ServiceCOE - Stress and Mental Health Survey</h1>
      <div className="rating-options">
        {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((option) => (
          <label key={option} className="rating-label">
            <input
              type="radio"
              name="rating"
              value={option}
              onChange={handleRatingChange}
              checked={rating === option}
            />
            {option}  {stressLevels[option]}
          </label>
        ))}
      </div>
    </div>
  );
};

export default StressSurvey;

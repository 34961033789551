import React from 'react';
import { Routes, Route } from "react-router-dom";
import Login from './components/userfeedback/Login';
import Rating from './components/supportfeedback/Rating'
import ThankYouPage from './components/userthankyou/ThankYouPage'
import ThankYou from './components/supportthankyou/ThankYou';


function App() {
  return (
    <div className="App">
        <Routes>
        <Route path="/Jsoan" component={Login} element={<Login />} />
        <Route path="/Todd" component={Login} element={<Login />} />
        <Route path="/Brooklyn" component={Login} element={<Login />} />
        <Route path="/Stacia" component={Login} element={<Login />} />
        <Route path="/Tony" component={Login} element={<Login />} />
        <Route path="/ratings" component={Rating} element={<Rating />} />
        <Route path="/thankyou" component={ThankYouPage} element={<ThankYouPage />} />
        <Route path="/thanksyou" component={ThankYou} element={<ThankYou />} />
        </Routes>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import './Login.css'; 

const StressSurvey = () => {
  const [rating, setRating] = useState(11); 

  let uuid = uuidv4();
  const convertedString = uuid.toString().substring(0, 8);

  const handleRatingChange = async (event) => {
    const newRating = parseInt(event.target.value);
    setRating(newRating);
  const pathnameWithoutSlash = window.location.pathname.slice(1);
  console.log("rating====",newRating);
  console.log("rating------------------",pathnameWithoutSlash);
  console.log("*****************",convertedString);

  try {
    const response = await axios.post('https://api.datascalp.com/apiv1/flightbooking/userFeedbackSurveysadddb', {
      rating: newRating,
      username: pathnameWithoutSlash,
      confirmation_no:convertedString,
    });
    if (response.status === 200) {
      console.log('Rating submitted successfully');
      window.location.href = `/thankyou?=${encodeURIComponent(convertedString)}`;
    } else {
      console.error('Failed to submit rating');
    }
  } catch (error) {
    console.error('Error submitting rating:', error);
  }
};

  const stressLevels = {
    10: 'Excellent',
    9: '',
    8: '',
    7: '',
    6: '',
    5: 'Mediocre',
    4: '',
    3: '',
    2: '',
    1: '',
    0: 'Poor',
  };

  return (
    <div className="survey-container">
      <div style={{ textAlign: 'center' }}>
        <h2>Your Opinion Matters</h2>
      </div>
      <p>
        <b>Please rate your experience</b>
      </p>
      <div className="rating-options">
        {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((option) => (
          <label key={option} className="rating-label">
            <input
              type="radio"
              name="rating"
              value={option}
              onChange={handleRatingChange}
              checked={rating === option}
            />
            {option}  {stressLevels[option]}
          </label>
        ))}
      </div>
    </div>
  );
};

export default StressSurvey;

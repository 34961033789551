import React from 'react';
import './ThankYouPage.css'; // Import the CSS file

const ThankYouPage = () => {
  const currentURL = new URL(window.location.href);
  const queryString = currentURL.search;
  const keyValue = queryString.split('=');
  const convertedString = keyValue[1];
  
  console.log("2222222222",convertedString);
  return (
    <div className="thank-you-container">
      <h2>Thank You for Your Feedback!</h2>
      <p>Your Confirmation number is {convertedString}.</p>
    </div>
  );
};

export default ThankYouPage;

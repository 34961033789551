import React from 'react';
import './ThankYou.css'; // Import the CSS file

const ThankYou = () => {
  console.log("=======================",window.location);
  return (
    <div className="thank-you-container">
      <h2>Thank You for Your Feedback!</h2>
      <p>Your feedback has been successfully submitted.</p>
    </div>
  );
};

export default ThankYou;
